import {Container, Spinner} from "react-bootstrap";
import React from "react";

function FullscreenSpinner(props){
    return (
        <Container>
            <div className="d-flex justify-content-center">
                <Spinner animation="border" size="lg"/>
            </div>
        </Container>
    );
}

export default FullscreenSpinner;