import axios from "axios";

class Organization {
    constructor(json) {
        this.json = json.organization;
        this.roles = json.roles;
    }

    get id(){
        return this.json.id;
    }

    get private(){
        return this.json.private;
    }

    get name(){
        if(this.json.name === null)
            return `Organization ${this.id}`;
        return this.json.name;
    }

    get startDate(){
        if (this.json.startDate !== null)
            return new Date(Date.parse(this.json.startDate + "Z"))
        else
            return null;
    }

    get endDate(){
        if (this.json.endDate !== null)
            return new Date(Date.parse(this.json.endDate + "Z"));
        else
            return null;
    }

    hasRole(role){
        return this.roles.includes(role);
    }
}


class Status {
    constructor(json) {
        this.json = json;
    }
    get battery(){
        return this.json.battery;
    }
    get systemStatus(){
        return this.json.systemStatus;
    }
    get firmwareId(){
        return this.json.firmwareId;
    }
    get timestamp(){
        // Parse as UTC
        return Date.parse(this.json.timestamp + "Z");
    }
}

class Instantaneous {
    constructor(json) {
        this.json = json
    }
    get timestamp(){
        // Parse as UTC
        return Date.parse(this.json.timestamp + "Z");
    }
}


const formFields = [
    "age",
    "caloriesGoal",
    "clockFormat",
    "distanceGoal",
    "gender",
    "height",
    "hrDutyCycle",
    "language",
    "name",
    "sleepGoal",
    "timezone",
    "units",
    "weight",
    "stepsGoal"
];


class Config {
    constructor(json) {
        this.json = json
    }

    get name() {
        return this.json?.name;
    }

    get form() {
        return  Object.fromEntries(
            Object
                .entries(this.json)
                .filter(([key]) => formFields.includes(key))
        );
    }
}

class Wearable {
    constructor(wearableJson, statusJson, instantaneousJson, configJson) {
        this.json = wearableJson;
        this.status = new Status(statusJson);
        this.instantaneous = new Instantaneous(instantaneousJson);
        this.config = new Config(configJson);
    }

    get firstSeen() {
        return new Date(Date.parse(this.json.firstSeen + "Z"));
    }

    get lastSeen(){
        return new Date(Date.parse(this.json.lastSeen + "Z"));
    }

    get id(){
        return this.json.id;
    }

    get deviceId(){
        return this.json.deviceId;
    }
}

async function getOrganizations(){
    const url = "/api/v1/organization";
    const response = await axios.get(url);
    return {
        organizations: response.data.organizations.map(org => new Organization(org)),
        loggedIn: response.data.loggedIn
    };
}

async function getApiConfig(){
    const response = await axios.get(`/api/v1/config`);
    return response.data;
}

async function getWearables(organizationId){
    const response = await axios.get(`/api/v1/organization/${organizationId}/wearables`);
    const wearables = response.data.result.map(result =>
        new Wearable(result.wearable, result.status, result.instantaneous, result.config)
    );
    return {
        wearables,
        updated: new Date(),
        raw: JSON.stringify(response.data.result)
    };
}

class MetricsResult {
    constructor(json) {
        this.json = json;
    }

    get hourly() {
        return this.json.hourly;
    }

    get range() {
        return this.json.range;
    }

    get sleep() {
        return this.json.sleep;
    }

    sumMetric(metric) {
        const vals = this.hourly.map(row => row[metric]);
        if (vals.length === 0){
            return 0;
        }
        return vals.reduce((a,b) => a+b);
    }

    minRange(metric){
        const vals = this.range.map(row => row[metric+"_min"]).filter(item => item !== null);
        if (vals.length === 0){
            return NaN;
        }
        return vals.reduce((a, b) => Math.min(a,b));
    }

    maxRange(metric){
        const vals = this.range.map(row => row[metric+"_max"]).filter(item => item !== null);
        if (vals.length === 0){
            return NaN;
        }
        return vals.reduce((a, b) => Math.max(a,b));
    }

    avgRange(metric) {
        const vals = this.range.map(row => row[metric+"_max"])
            .filter(item => item !== null)
            .map(val => {return {val, ct: 1}; });

        if (vals.length === 0){
            return NaN;
        }
        const result = vals.reduce((a, b) => {return {val: a.val + b.val, ct: a.ct + b.ct}});
        return result.val / result.ct;
    }
}

async function getWearableMetrics(organizationId, wearableId, startTime, endTime){
    const url = `/api/v1/organization/${organizationId}/wearables/${wearableId}/aggregate`;
    const response = await axios.get(url, {
        params: {startTime, endTime}
    });

    return new MetricsResult(response.data);
}

async function postWearableConfig(organizationId, wearableId, config){
    const url = `/api/v1/organization/${organizationId}/wearables/${wearableId}/config`;
    const response = await axios.post(url, {config});
    return response;
}

export  { getApiConfig, getOrganizations, Organization, getWearables, getWearableMetrics, postWearableConfig};