import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {useQuery} from "react-query";
import {getWearableMetrics} from "./api";
import {OrgContext} from "./OrgWrapper";

const WearableContext = React.createContext({});

function startOfDay(date){
    let start = new Date(date);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    return start;
}

function today(){
    return startOfDay(new Date());
}

function WearableProvider(props){

    const {context, children} = props;

    const {organization, wearables} = context;

    const {wearableId} = useParams();
    const [date, setDate] = useState(() => today());

    const wearable = useMemo(
        () => (wearables && wearableId) ? wearables.find(item => item.id === +wearableId) : undefined,
        [wearables, wearableId]
    );

    const wearablesQuery = useQuery(
        `wearable:${organization?.id}:${wearable?.id}:${date.getDate()}`,
        async (arg) => {
            return await getWearableMetrics(organization.id, wearable.id, date.getTime()/1000, date.getTime()/1000 + 86400);
        },
        {
            refetchOnWindowFocus: true,
            enabled: wearable != null && organization != null
        }
    );

    useEffect(() => {
        if (wearable !== undefined){
            if (wearable.lastSeen < date || startOfDay(wearable.firstSeen) > date){
                setDate(startOfDay(wearable.lastSeen));
            }
        }
        return () => {}
    }, [wearable]);

    const wearablesMetricsDataAvailable = wearablesQuery.isSuccess && wearablesQuery.data !== undefined;

    const wearableMetrics = wearablesMetricsDataAvailable ? wearablesQuery.data : undefined;

    const newContext = {...context,  wearable, wearableMetrics, wearablesMetricsDataAvailable, date, setDate, dateGetDate: date.getTime()};

    return <WearableContext.Provider value={newContext}>
        {children}
    </WearableContext.Provider>;

}
function WearableWrapper(props){

    const {children} = props;
    return <OrgContext.Consumer>
        {
            context => <WearableProvider context={context}>{children}</WearableProvider>

        }
    </OrgContext.Consumer>

}
WearableWrapper.propTypes = {
    children: PropTypes.element,
    organizations: PropTypes.array,
    wearables: PropTypes.array
}
export {WearableWrapper as default, WearableContext}