import {useQuery} from "react-query";
import {Sensor, Gateway, getApiConfig, getOrganizations} from "./api";
import {Outlet, Route, Routes, useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import React, {Suspense, useState, useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import Home from "./Home";
import Layout from "./Layout";
import OrgView from "./OrgView";
import WearableWrapper, {WearableContext} from "./WearableWrapper";
import WearableView from "./WearableView";
import WearableOverviewView from "./wearable_views/WearableOverviewView";
import WearableHeartrateView from "./wearable_views/WearableHeartrateView";
import WearableStepView from "./wearable_views/WearableStepView";
import {OrgRouteWrapper} from "./OrgWrapper";
import FullscreenSpinner from "./FullscreenSpinner";
import WearablePowerView from "./wearable_views/WearablePowerView";
import WearableCalorieView from "./wearable_views/WearableCalorieView";
import WearableTemperatureView from "./wearable_views/WearableTemperatureView";
import WearableSleepView from "./wearable_views/WearableSleepView";
import WearableConfigView from "./wearable_views/WearableConfigView";


function Router(props){

    const {
        organization, setSelectedOrg,
    } = props;

    const [timeframe, setTimeframe] = useState(null);


    // Data queries
    const configQuery = useQuery(
        `config`,
        async (arg) => {
            return await getApiConfig();
        },
        {
            refetchOnWindowFocus: false,
            enabled: true
        }
    );

    const organizationQuery = useQuery(
        `organizations`,
        async (arg) => {
            return await getOrganizations();
        },
        {
            refetchOnWindowFocus: true,
            enabled: true
        }
    );

    const configDataAvailable = configQuery.isSuccess && configQuery.data !== null;
    const organizationDataAvailable = organizationQuery.isSuccess && organizationQuery.data != null;

    if (!configDataAvailable || !organizationDataAvailable) {
        return <FullscreenSpinner />;
    }

    const organizations = organizationQuery.data.organizations;
    const config = configQuery.data;
    const loggedIn = organizationQuery.data.loggedIn;

    return <Routes>
        <Route path="/"
               element={<Layout config={config} organizations={organizations} loggedIn={loggedIn} />}
        >
            <Route index element={<Home organizations={organizations} loggedIn={loggedIn} />}/>
            <Route path="org">
                <Route path=":orgId" element={<OrgRouteWrapper organizations={organizations}> <Outlet /></OrgRouteWrapper>}>
                    <Route index element={
                        <Container><OrgView /></Container>
                    }/>

                    <Route path="wearable/:wearableId" element={
                        <WearableWrapper organizations={organizations}>
                            <WearableContext.Consumer>
                                {
                                    context =>
                                        (context.wearable === undefined || context.organization === undefined)
                                            ? <FullscreenSpinner />
                                            : <WearableView
                                                organization={context.organization}
                                                wearable={context.wearable}
                                                date={context.date}
                                                setDate={context.setDate}
                                            >
                                                <Outlet/>
                                            </WearableView>

                                }
                            </WearableContext.Consumer>
                        </WearableWrapper>
                    }>
                        <Route path="overview" element={
                            <WearableContext.Consumer>
                                {context =>
                                    <WearableOverviewView
                                        organization={context.organization}
                                        wearable={context.wearable}
                                        wearableMetrics={context.wearableMetrics}
                                        context={context}
                                    />
                                }
                            </WearableContext.Consumer>
                        } />
                        <Route path="steps" element={
                            <WearableContext.Consumer>
                                {context =>
                                    <WearableStepView
                                        organization={context.organization}
                                        wearable={context.wearable}
                                        wearableMetrics={context.wearableMetrics}
                                        context={context}
                                    />
                                }
                            </WearableContext.Consumer>
                        } />
                        <Route path="heart-rate" element={
                            <WearableContext.Consumer>
                                {context =>
                                    <WearableHeartrateView
                                        organization={context.organization}
                                        wearable={context.wearable}
                                        wearableMetrics={context.wearableMetrics}
                                        context={context}
                                    />
                                }
                            </WearableContext.Consumer>
                        } />
                        <Route path="calories" element={
                            <WearableContext.Consumer>
                                {context =>
                                    <WearableCalorieView
                                        organization={context.organization}
                                        wearable={context.wearable}
                                        wearableMetrics={context.wearableMetrics}
                                        context={context}
                                    />
                                }
                            </WearableContext.Consumer>
                        } />
                        <Route path="sleep" element={
                            <WearableContext.Consumer>
                                {context =>
                                    <WearableSleepView
                                        organization={context.organization}
                                        wearable={context.wearable}
                                        wearableMetrics={context.wearableMetrics}
                                        context={context}
                                    />
                                }
                            </WearableContext.Consumer>
                        } />
                        <Route path="power" element={
                            <WearableContext.Consumer>
                                {context =>
                                    <WearablePowerView
                                        organization={context.organization}
                                        wearable={context.wearable}
                                        wearableMetrics={context.wearableMetrics}
                                        context={context}
                                    />
                                }
                            </WearableContext.Consumer>
                        } />
                        <Route path="temperature" element={
                            <WearableContext.Consumer>
                                {context =>
                                    <WearableTemperatureView
                                        organization={context.organization}
                                        wearable={context.wearable}
                                        wearableMetrics={context.wearableMetrics}
                                        context={context}
                                    />
                                }
                            </WearableContext.Consumer>
                        } />
                        <Route path="config" element={
                            <WearableContext.Consumer>
                                {context =>
                                    <WearableConfigView
                                        organization={context.organization}
                                        wearable={context.wearable}
                                        wearableMetrics={context.wearableMetrics}
                                        context={context}
                                    />
                                }
                            </WearableContext.Consumer>
                        } />
                    </Route>

                </Route>
            </Route>
        </Route>
    </Routes>;
}

Router.propTypes = {
    organization: PropTypes.object,
    setSelectedOrg: PropTypes.func
}

export default Router;