import {useBoxDimensions} from "../windowDimensions";
import React from "react";
import FullscreenSpinner from "../FullscreenSpinner";
import RangePlot from "../plots/RangePlot";
import {Card, Row, Col} from "react-bootstrap";


function WearableHeartrateView(props) {
   const {organization, wearable, wearableMetrics, context} = props;

    const {width, height} = useBoxDimensions("chart-container");

    if (wearableMetrics === undefined){
        return <FullscreenSpinner />;
    }

    const timespan = [context.date.getTime(),  context.date.getTime() + 86400 * 1000];

    const minTemp = wearableMetrics.minRange("skin_temp");
    const maxTemp = wearableMetrics.maxRange("skin_temp");

    return <>
        <Row className="metric-bar">
            <Col md={3} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label ">Average</p>
                        <p className="metric-value display-5">{ (wearableMetrics.avgRange("skin_temp")).toFixed(1)} &#8451;</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={3} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label ">Range</p>
                        <p className="metric-value display-5">
                            { minTemp.toFixed(1)} - { maxTemp.toFixed(1)} &#8451;
                        </p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <RangePlot
            timespan={timespan}
            wearableMetrics={wearableMetrics}
            width={width - 10}
            height={height - 10}
            title="Skin Temperature"
            minCol="skin_temp_min"
            maxCol="skin_temp_max"
            yAxisLabel="Degrees Celsius"
        />
    </>;
}

export default WearableHeartrateView;