import {useParams} from "react-router-dom";
import React, {useMemo} from "react";
import {useQuery} from "react-query";
import {getWearables} from "./api";
import PageContext from "./WearableWrapper";

const OrgContext = React.createContext({});

function OrgRouteWrapper(props){
        const {organizations, children} = props;
        const {orgId} = useParams();
        return <OrgWrapper
            organizations={organizations}
            orgId={orgId}
        >
            {children}
        </OrgWrapper>
}

function OrgWrapper(props){
    const {organizations, children, orgId} = props;

    const organization = useMemo(
        () => organizations.find(orgItem => orgItem.id === +orgId),
        [organizations, orgId]
    );

    const wearablesQuery = useQuery(
        `wearables:${orgId}`,
        async (arg) => {
            return await getWearables(organization.id);
        },
        {
            refetchOnWindowFocus: true,
            enabled: orgId !== undefined
        }
    );

    const wearablesDataAvailable = wearablesQuery.isSuccess && wearablesQuery.data != null;

    const wearables = wearablesDataAvailable ? wearablesQuery.data.wearables : undefined;

    const context = {organizations, organization, wearables, wearablesDataAvailable, wearablesQuery};

    return <OrgContext.Provider value={context}>
        {children}
    </OrgContext.Provider>;
}

export {OrgWrapper as default, OrgContext, OrgRouteWrapper};