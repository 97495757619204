import React from 'react';
import {useBoxDimensions} from "../windowDimensions";
import FullscreenSpinner from "../FullscreenSpinner";
import SumPlot from "../plots/SumPlot";
import SleepPlot from "../plots/SleepPlot";

function WearableSleepView(props){
    const {organization, wearable, wearableMetrics, context} = props;

    const {width, height} = useBoxDimensions("chart-container");

    if (wearableMetrics === undefined){
        return <FullscreenSpinner />;
    }

    const timespan = [context.date.getTime(),  context.date.getTime() + 86400 * 1000];

    return <SleepPlot
        timespan={timespan}
        wearableMetrics={wearableMetrics}
        width={width - 10}
        height={height - 10}
        title="Sleep"
    />
}

export default WearableSleepView;