import {Row, Col, Container, Card} from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import WelcomeView from "./WelcomeView";
import {LinkContainer} from "react-router-bootstrap";
import WearableList from "./WearableList";
import OrgWrapper, {OrgContext} from "./OrgWrapper";
import OrgView from "./OrgView";


function Home(props) {
    const {organizations, loggedIn} = props;

    const limit = (organizations.length > 1) ? 2 : undefined;

    if(organizations.length === 0){
        return <WelcomeView loggedIn={loggedIn} />;
    }

    return <Container>
        {
            organizations.map(org => <>
                <div className="org-box" key={org.id}>

                        <div>
                            <LinkContainer to={`/org/${org.id}`}>
                                <h3> {org.name} </h3>
                            </LinkContainer>
                        <OrgWrapper organizations={organizations} orgId={org.id}>
                                    <OrgContext.Consumer>
                                    { context => <p>
                                        <WearableList
                                            organization={context.organization} wearables={context.wearables}
                                            limit={limit}
                                        />
                                    </p>}
                                    </OrgContext.Consumer>
                        </OrgWrapper>
                        </div>

                </div>
                <hr />
            </>)
        }
        <p><a target="_blank" href="/apidocs/">API Docs</a></p>
    </Container>
}

Home.propTypes = {
    organizations: PropTypes.array,
    loggedIn: PropTypes.bool
};

export default Home;