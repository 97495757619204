import {Container, Navbar, NavDropdown, Nav} from "react-bootstrap";
import {useQuery} from "react-query";
import {getOrganizations, Organization, getApiConfig} from "./api";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {LockFill} from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap';


function NavbarView(props) {

    const {
        navbarItems,
        selectedOrganization,
        organizations,
        config,
        loggedIn
    } = props;

    const orgTitle = (selectedOrganization !== undefined) ? selectedOrganization.name : "Organizations";


    return (
        <Navbar bg="light" expand="lg">
            <Container fluid="lg">
                <Navbar.Brand>Matrix Industries - Perceptive</Navbar.Brand>
                <Navbar.Toggle></Navbar.Toggle>
                <Navbar.Collapse className="justify-content-end">
                    {navbarItems}
                    <NavDropdown title={orgTitle}>
                        {organizations.map((org) =>
                            <LinkContainer to={`/org/${org.id}`} key={org.id} activeClassName="active-normal"><NavDropdown.Item>{org.name}</NavDropdown.Item></LinkContainer>
                        )}
                        <NavDropdown.Divider />
                        { config !== null
                            ? (
                                loggedIn
                                ? <NavDropdown.Item key={"logout"} href={config.logoutUrl}>Logout</NavDropdown.Item>
                                : <NavDropdown.Item key={"login"} href={config.authenticationUrl}>Sign In</NavDropdown.Item>
                            )
                            : null}
                    </NavDropdown>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

NavbarView.propTypes = {
    setSelectedOrg: PropTypes.func,
    selectedOrganization: PropTypes.object,
    navbarItems: PropTypes.element,
    config: PropTypes.object,
    loggedIn: PropTypes.bool,
    organizations: PropTypes.array
}

export default NavbarView;