import NavbarView from "./NavbarView";
import React from "react";
import {Outlet} from "react-router-dom";
import PropTypes from "prop-types";

function Layout(props){

    const {config, organizations, loggedIn} = props;

    return <div className="top-level">
        <NavbarView
                config={config}
                organizations={organizations}
                loggedIn={loggedIn}
        />
        <Outlet />
    </div>
}

Layout.propTypes = {
    config: PropTypes.object,
    organizations: PropTypes.array,
    loggedIn: PropTypes.bool
}
export default Layout