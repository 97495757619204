import {useBoxDimensions} from "../windowDimensions";
import React, {useId, useMemo, useState} from "react";
import FullscreenSpinner from "../FullscreenSpinner";
import RangePlot from "../plots/RangePlot";
import {Card, Col, Form, Row, Button, Dropdown, Toast} from "react-bootstrap";
import {useMutation, useQueryClient} from "react-query";
import {postWearableConfig} from "../api";
import {useNavigate} from "react-router-dom";
import moment from "moment-timezone";


function RadioGroup(props) {
    const {options, selectedValue, onChangeSelected} = props;
    const groupId = useId();


    return options.map((option, i) =>
        <Form.Check
            inline
            key={i}
            label={option.label}
            name={`group-${groupId}`}
            type="radio"
            id={`radio-${groupId}-${i}`}
            checked={selectedValue === option.value}
            onClick={e => onChangeSelected(option.value)}
        />
    );
}

function DropdownGroup(props) {
    const {options, selectedValue, onChangeSelected} = props;

    const selectedOption = options.find(option => selectedValue === option.value);

    return <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {selectedOption !== undefined ? selectedOption?.label : "<Not Set>"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {
                options.map((option, i) =>
                    <Dropdown.Item key={i} onClick={e => onChangeSelected(option.value)}>{option.label}</Dropdown.Item>
                )
            }
        </Dropdown.Menu>
    </Dropdown>
}

function WearableConfigView(props) {
    const {organization, wearable, wearableMetrics, context} = props;
    const {wearablesQuery} = context;

    const [form, setForm] = useState(wearable.config.form);

    const timezones = useMemo(() => {
        const supportedZones = moment.tz.names().filter(name => name.includes("Etc/GMT"));
        const zonesOptions = supportedZones.flatMap(zoneName => {
            const zoneObject = moment.tz.zone(zoneName);
            if (zoneObject.offsets.length === 1){
                return [{value: zoneObject.offsets[0], label: zoneObject.name}];
            } else {
                return [];
            }

        });
        const zonesOptionsUnique = [
            ...new Map(zonesOptions.map((item) => [item.value, item])).values(),
        ];
        zonesOptionsUnique.sort((zone1, zone2) => zone1.value - zone2.value);
        return zonesOptionsUnique;
    }, []);


    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const mutation = useMutation(configUpdate => {
        return postWearableConfig(configUpdate.organizationId, configUpdate.wearableId, configUpdate.config);
    }, {
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries();
            wearablesQuery.refetch();
            navigate(`/org/${organization.id}/`);
        },
        onSettled: (data, error, variables, context) => {
            console.log({data, event: "Settled"});
        }
    })

    function keyHandler(e) {
        e.stopPropagation();
    }


    function submit(e) {
        e.preventDefault();

        if (mutation.isError) {
            mutation.reset();
        }
        const result = mutation.mutate({organizationId: organization.id, wearableId: wearable.id, config: form});

    }

    if (mutation.isLoading) {
        return <FullscreenSpinner/>;
    }
    return <div>
        <Toast onClose={() => {
        }} show={mutation.isError} animation={false}>
            <Toast.Body>Failed to save. Please try again.</Toast.Body>
        </Toast>
        <Row>
            <Col md={{span: 8, offset: 2}}>
                <Form onSubmit={submit} onKeyDown={keyHandler}>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label><b>Name</b></Form.Label>
                        <Form.Control
                            type="name" placeholder="name"
                            value={form.name}
                            onChange={e => {
                                setForm(prevForm => {
                                    return {...prevForm, name: e.target.value};
                                })
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formAge">
                        <Form.Label><b>Age</b></Form.Label>
                        <Form.Control
                            type="number" placeholder="age"
                            value={form.age}
                            onChange={e => {
                                setForm(prevForm => {
                                    return {...prevForm, age: parseInt(e.target.value)};
                                })
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formWeight">
                        <Form.Label><b>Weight (kg)</b></Form.Label>
                        <Form.Control
                            type="number" placeholder="weight"
                            value={form.weight}
                            onChange={e => {
                                setForm(prevForm => {
                                    return {...prevForm, weight: parseInt(e.target.value)};
                                })
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formHeight">
                        <Form.Label><b>Height (mm)</b></Form.Label>
                        <Form.Control
                            type="number" placeholder="height"
                            value={form.height}
                            onChange={e => {
                                setForm(prevForm => {
                                    return {...prevForm, height: parseInt(e.target.value)};
                                })
                            }}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label><b>Clock Format</b></Form.Label>
                        <div className="mb-3">
                            <RadioGroup
                                selectedValue={form.clockFormat}
                                options={[
                                    {value: 0, label: "12-hour"},
                                    {value: 1, label: "24-hour"},
                                ]}
                                onChangeSelected={value => setForm(prevForm => {
                                    return {...prevForm, clockFormat: value};
                                })}
                            />
                        </div>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label><b>Heart Rate Duty Cycle</b></Form.Label>
                        <div className="mb-3">
                            <RadioGroup
                                selectedValue={form.hrDutyCycle}
                                options={[
                                    {value: 1, label: "1 Minute"},
                                    {value: 5, label: "5 Minute"},
                                    {value: 10, label: "10 Minute"},
                                    {value: 20, label: "20 Minute"}
                                ]}
                                onChangeSelected={value => setForm(prevForm => {
                                    return {...prevForm, hrDutyCycle: value};
                                })}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Language</b></Form.Label>
                        <div className="mb-3">
                            <DropdownGroup
                                selectedValue={form.language}
                                options={[
                                    {value: 0, label: "English"},
                                    {value: 1, label: "Simplified Chinese"},
                                    {value: 2, label: "Japanese"},
                                    {value: 3, label: "Russian"},
                                    {value: 4, label: "German"},
                                    {value: 5, label: "French"},
                                    {value: 6, label: "Italian"},
                                    {value: 7, label: "Spanish"},
                                    {value: 8, label: "Portuguese"},
                                    {value: 9, label: "Korean"},
                                    {value: 10, label: "Traditional Chinese"},
                                    {value: 11, label: "Czech"}
                                ]}
                                onChangeSelected={value => setForm(prevForm => {
                                    return {...prevForm, language: value};
                                })}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Units</b></Form.Label>
                        <div className="mb-3">
                            <RadioGroup
                                selectedValue={form.units}
                                options={[
                                    {value: 0, label: "Imperial"},
                                    {value: 1, label: "Metric"}
                                ]}
                                onChangeSelected={value => setForm(prevForm => {
                                    return {...prevForm, units: value};
                                })}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Gender</b></Form.Label>
                        <div className="mb-3">
                            <RadioGroup
                                selectedValue={form.gender}
                                options={[
                                    {value: 0, label: "Male"},
                                    {value: 1, label: "Female"}
                                ]}
                                onChangeSelected={value => setForm(prevForm => {
                                    return {...prevForm, gender: value};
                                })}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Timezone</b></Form.Label>
                        <div className="mb-3">
                            <DropdownGroup
                                selectedValue={form.timezone}
                                options={timezones}
                                onChangeSelected={value => setForm(prevForm => {
                                    return {...prevForm, timezone: value};
                                })}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCalories">
                        <Form.Label><b>Calories Goal (kilocalories)</b></Form.Label>
                        <Form.Control
                            type="number" placeholder="calories goal"
                            value={form.caloriesGoal}
                            onChange={e => {
                                setForm(prevForm => {
                                    return {...prevForm, caloriesGoal: parseInt(e.target.value)};
                                })
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formDistance">
                        <Form.Label><b>Distance Goal (meters)</b></Form.Label>
                        <Form.Control
                            type="number" placeholder="distance goal"
                            value={form.distanceGoal}
                            onChange={e => {
                                setForm(prevForm => {
                                    return {...prevForm, distanceGoal: parseInt(e.target.value)};
                                })
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formSteps">
                        <Form.Label><b>Steps Goal</b></Form.Label>
                        <Form.Control
                            type="number" placeholder="steps goal"
                            value={form.stepsGoal}
                            onChange={e => {
                                setForm(prevForm => {
                                    return {...prevForm, stepsGoal: parseInt(e.target.value)};
                                })
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formSleep">
                        <Form.Label><b>Sleep Goal (minutes)</b></Form.Label>
                        <Form.Control
                            type="number" placeholder="sleep goal"
                            value={form.sleepGoal}
                            onChange={e => {
                                setForm(prevForm => {
                                    return {...prevForm, sleepGoal: parseInt(e.target.value)};
                                })
                            }}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Form>
            </Col>
        </Row>
    </div>;
}

export default WearableConfigView;