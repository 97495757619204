import {useBoxDimensions} from "../windowDimensions";
import React from "react";
import FullscreenSpinner from "../FullscreenSpinner";
import RangePlot from "../plots/RangePlot";
import {Card, Col, Row} from "react-bootstrap";


function WearableHeartrateView(props) {
   const {organization, wearable, wearableMetrics, context} = props;

    const {width, height} = useBoxDimensions("chart-container");

    if (wearableMetrics === undefined){
        return <FullscreenSpinner />;
    }

    const timespan = [context.date.getTime(),  context.date.getTime() + 86400 * 1000];

    const minHeartRate = wearableMetrics.minRange("heart_rate");
    const maxHeartRate = wearableMetrics.maxRange("heart_rate");
    return <>
        <Row className="metric-bar">
            <Col md={3} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label ">Average</p>
                        <p className="metric-value display-5">{ (wearableMetrics.avgRange("heart_rate"))?.toFixed(1)} BPM</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={3} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label ">Range</p>
                        <p className="metric-value display-5">
                            { minHeartRate?.toFixed(1)} - { (maxHeartRate)?.toFixed(1)} BPM
                        </p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <RangePlot
            timespan={timespan}
            wearableMetrics={wearableMetrics}
            width={width - 10}
            height={height - 10}
            title="Heart Rate"
            minCol="heart_rate_min"
            maxCol="heart_rate_max"
            yAxisLabel="BPM"
        />
        </>
}

export default WearableHeartrateView;