import {useQuery} from "react-query";
import {getOrganizations, getWearables} from "./api";
import {Card, Nav, Row, Col, Button} from "react-bootstrap";
import {LockFill, Pencil} from "react-bootstrap-icons";
import {LinkContainer} from "react-router-bootstrap";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import Moment from "react-moment";
import PropTypes from "prop-types";
import WearableCard from "./WearableCard";
import {NonIdealState} from "@blueprintjs/core";

function WearableList(props){

    const {organization, wearables, debug, limit} = props;

    const [expand, setExpand] = useState(false);

    const wearablesTop = (limit !== undefined && !expand && wearables)
        ? wearables.slice(0, limit)
        : wearables;

    return <Row>
        {
            wearablesTop?.map(wearable =>
                <Col key={wearable.id} md={6}>
                        <WearableCard organization={organization} wearable={wearable} />
                    { debug ? <pre>{JSON.stringify(wearable, null, 2)}</pre> : null }
                </Col>
            )
        }
        {
            (wearablesTop?.length !== wearables?.length)
            ? <Button variant="link" onClick={() => setExpand(true)}> Show {wearables.length - wearablesTop.length} more </Button>
            : null
        }
        {
            wearablesTop?.length === 0 ?
            <NonIdealState
                title="No Wearables Found"
                description="Wearable data has not yet been collected. Ensure that wearables are reporting to a connected gateway."
                icon="folder-open"
            />
            : null
        }
    </Row>;
}

WearableList.propTypes = {
    organization: PropTypes.object,
    wearables: PropTypes.array,
    debug: PropTypes.bool
}

export default WearableList;