import React from 'react';
import {useBoxDimensions} from "../windowDimensions";
import FullscreenSpinner from "../FullscreenSpinner";
import SumPlot from "../plots/SumPlot";
import {Card, Row, Col} from "react-bootstrap";

function WearableStepView(props){
    const {organization, wearable, wearableMetrics, context} = props;

    const {width, height} = useBoxDimensions("chart-container");

    if (wearableMetrics === undefined){
        return <FullscreenSpinner />;
    }

    const timespan = [context.date.getTime(),  context.date.getTime() + 86400 * 1000];

    return <>
        <Row className="metric-bar">
            <Col md={2} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">Steps</p>
                        <p className="metric-value display-4">{ wearableMetrics.sumMetric("delta_run_steps") + wearableMetrics.sumMetric("delta_walk_steps")}</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={2} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">Distance</p>
                        <p className="metric-value display-4">{ wearableMetrics.sumMetric("delta_distance") } m</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <SumPlot
            timespan={timespan}
            wearableMetrics={wearableMetrics}
            width={width - 10}
            height={height - 10}
            title="Steps"
            yCols={[
                {y:"delta_run_steps", name: "Running"},
                {y:"delta_walk_steps", name: "Walking"}
            ]}
            yAxisLabel="Step Count"
        />
    </>
}

export default WearableStepView;