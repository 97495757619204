import Plot from "../../node_modules/react-plotly.js/react-plotly";
import React from "react";

function SumPlot(props){
    const {timespan, wearableMetrics, yCols, width, height, title, yAxisLabel} = props;

    const x = wearableMetrics.hourly.map(row => new Date((row.timestamp + 1800) * 1000));

    function buildY(y, f) {
        if(f !== undefined)
            return wearableMetrics.hourly.map(row => f(row[y]));
        else
            return wearableMetrics.hourly.map(row => row[y]);
    }

    return <div className="chart-container">
        <Plot
            data={
                yCols.map(col => {
                    return {type: 'bar', x, y: buildY(col.y, col.f), name: col.name}
                })
            }
            layout={{
                width,
                height,
                title,
                titlefont: {
                    family: 'Arial, sans-serif',
                    size: 18,
                    color: 'black',
                },
                barmode: 'stack',
                xaxis: {
                    range: timespan,
                    tickfont: {
                        size: 14
                    }
                },
                legend: {
                    font: {
                        size: 14
                    }
                },
                yaxis: {
                    title: yAxisLabel,
                    titlefont: {
                      family: 'Arial, sans-serif',
                      size: 18,
                      color: 'black'
                    },
                    tickfont: {
                        size: 14
                    }
                }
            }}
            config={{displayModeBar: false}}
          />
    </div>;
}
export default SumPlot;