import Plot from "../../node_modules/react-plotly.js/react-plotly";
import React from "react";

function SleepPlot(props){
    const {timespan, wearableMetrics, width, height, title, yAxisLabel} = props;

    function sleepFunction(activity){
        switch (activity){
            case 1:
                return 0;

            case 2:
                return 1;

            case 3:
                return 2;

            default:
                return 3;
        }
    }

    let currentSegment = [];
    let sleepSegments = [currentSegment];

    const maxGap = 3600;

    wearableMetrics.sleep.forEach(row => {
        if (currentSegment.length > 0){
            const last = currentSegment[currentSegment.length-1];
            if (row[0] - last[0] > maxGap) {
                currentSegment = [];
                sleepSegments.push(currentSegment)
            }
        }
        currentSegment.push(row);
    });

    return <div className="chart-container">
        <Plot
            data={
                sleepSegments.map(segment => {
                        return {
                            mode: 'markers+lines',
                            x: segment.map(row => new Date(row[0] * 1000)),
                            y: segment.map(row => sleepFunction(row[1])),
                            line: {
                                color: 'blue'
                            },
                            hoverinfo: "x+y"
                        };
                    }
                )
            }
            layout={{
                width,
                height,
                title,
                titlefont: {
                  family: 'Arial, sans-serif',
                  size: 18,
                  color: 'black'
                },
                barmode: 'stack',
                xaxis: {
                    range: timespan,
                    tickfont: {
                        size: 14
                    }
                },
                yaxis: {
                    title: yAxisLabel,
                    titlefont: {
                      family: 'Arial, sans-serif',
                      size: 18,
                      color: 'black'
                    },
                    range: [-0.1, 3.02],
                    tickmode: 'array',
                    tickvals: [0, 1, 2, 3],
                    ticktext: ['Deep Sleep', 'Light <br /> Sleep', 'Restless <br /> Sleep', 'Awake'],
                    tickfont: {
                        size: 14
                    },
                    tickangle: 0
                },
                showlegend: false
            }}
            config={{displayModeBar: false}}
          />
    </div>;
}
export default SleepPlot;