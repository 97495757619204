import {useState, useEffect} from "react";

function getBoxDimensions(className, defaultObject = { width: 600, height: 600 , unavailable: true}){
    const components = document.getElementsByClassName(className);
    if(components.length === 0){
        return defaultObject;
    }
    const { clientWidth: width, clientHeight: height } = components[0];
    return {
        width,
        height,
        unavailable: false
    };
}

export function useBoxDimensions(className, deps=[]) {

    const originalDimensions = getBoxDimensions(className)
    const [windowDimensions, setWindowDimensions] = useState(originalDimensions);

    useEffect(() => {
        if(className === null){
            return null;
        }
        function handleResize() {
            const boxDimensions = getBoxDimensions(className);
            if(boxDimensions.unavailable){
                setTimeout(() => handleResize(), 100);
            }
            setWindowDimensions(boxDimensions);
        }
        // retry after after a moment in case render has no completed.

        setTimeout(() => handleResize(), 10);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, deps);

    return windowDimensions;
}

