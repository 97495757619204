import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Router from "./Router";

import {
    QueryClient,
    QueryClientProvider, useQuery,
} from 'react-query';
import {useState} from "react";

const queryClient = new QueryClient();

function App() {

    const [organization, setOrganization] = useState(null);

    return (
        <QueryClientProvider client={queryClient}>
            {
                <Router
                    organization={organization}
                    setSelectedOrg={setOrganization}
                />
            }
        </QueryClientProvider>
    );
}

export default App;
