import React from 'react';
import {useBoxDimensions} from "../windowDimensions";
import FullscreenSpinner from "../FullscreenSpinner";
import SumPlot from "../plots/SumPlot";
import {Card, Col, Row} from "react-bootstrap";


function WearablePowerView(props) {
    const {organization, wearable, wearableMetrics, context} = props;

    const {width, height} = useBoxDimensions("chart-container");

    if (wearableMetrics === undefined){
        return <FullscreenSpinner />;
    }

    const timespan = [context.date.getTime(),  context.date.getTime() + 86400 * 1000];

    return <>
        <Row className="metric-bar">
            <Col md={2} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label ">From Body</p>
                        <p className="metric-value display-5">{ (wearableMetrics.sumMetric("thermal_gen_sum") / 60).toFixed(1)} &#181;Wh </p>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={2} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label ">From Light</p>
                        <p className="metric-value display-5">{ (wearableMetrics.sumMetric("solar_gen_sum") / 60).toFixed(1) } &#181;Wh </p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <SumPlot
            timespan={timespan}
            wearableMetrics={wearableMetrics}
            width={width - 10}
            height={height - 10}
            title="Power"
            yCols={[
                {y:"solar_gen_sum", name: "Light", f:x => x / 60},
                {y:"thermal_gen_sum", name: "Body", f:x => x / 60}
            ]}
            yAxisLabel={"Microwatt-Hour"}
        />
    </>
}

export default WearablePowerView;