import {Container} from "react-bootstrap";
import WearableList from "./WearableList";
import {OrgContext} from "./OrgWrapper";
import FullscreenSpinner from "./FullscreenSpinner";
import React from "react";
import {NonIdealState} from "@blueprintjs/core";

function OrgView(props){
    return <div>
        <OrgContext.Consumer>
            { context =>
                <div className="org-view">
                    {
                        context.wearablesDataAvailable ?
                            <div>
                                <WearableList organization={context.organization} wearables={context.wearables}/>
                            </div>
                            : <FullscreenSpinner />
                    }

                </div>
            }
        </OrgContext.Consumer>
    </div>
}

export default OrgView;