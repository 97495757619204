import PropTypes from "prop-types";
import {Container} from "react-bootstrap";

function WelcomeView(props){
    const {loggedIn} = props;
    return <Container>
        <h1>Welcome to Perceptive by Matrix Industries</h1>
        {
            loggedIn ?
            <div>
                <p>You are currently not assigned to any organizations.</p>

                <p>Please contact support to be added</p>
            </div> : <div>
                Log In to view your devices.
            </div>
        }
    </Container>
}

WelcomeView.propTypes = {
    loggedIn: PropTypes.bool
}
export default WelcomeView