import {Container, Nav, Row, Col, Collapse, Button} from "react-bootstrap";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';
import { DatePicker } from "@blueprintjs/datetime";
import {useEffect, useState} from "react";
import Moment from "react-moment";
import {ArrowLeft, ArrowRight, ChevronDown} from "react-bootstrap-icons";
import {NonIdealState} from "@blueprintjs/core";


function WearableView(props) {

    const {organization, wearable, date, setDate} = props;

    const [datePickerOpen, setDatePickerOpen] = useState(false);

    console.log(wearable)
    const endDate = new Date(date.getTime() + 86400 * 1000)

    const prevDayEnabled = date > wearable?.firstSeen;
    const nextDayEnabled = endDate < wearable?.lastSeen;

    const navigate = useNavigate();
    const location = useLocation();

    const orgUrl = `/org/${organization?.id}`
    const prefix = `${orgUrl}/wearable/${wearable?.id}`;
    const routes = [
        {
            name: "Overview",
            path: `${prefix}/overview`
        },
        {
            name: "Steps",
            path: `${prefix}/steps`
        },
        {
            name: "Heart Rate",
            path: `${prefix}/heart-rate`
        },
        {
            name: "Calories",
            path: `${prefix}/calories`
        },
        {
            name: "Sleep",
            path: `${prefix}/sleep`
        },
        {
            name: "Power",
            path: `${prefix}/power`
        },
        {
            name: "Temperature",
            path: `${prefix}/temperature`
        }
    ];

    if(organization.roles.includes("admin")){
        routes.push({
            name: "Config",
            path: `${prefix}/config`
        });
    }


    function prevPage(page){
        const routeIndex = routes.findIndex(route => route.path === page);
        if (routeIndex > 0){
            navigate(routes[routeIndex - 1].path);
        }
    }

    function nextPage(page){
        const routeIndex = routes.findIndex(route => route.path === page);
        if (routeIndex + 1 < routes.length){
            navigate(routes[routeIndex + 1].path);
        }
    }

    function prevDay(){
        if(prevDayEnabled)
            setDate(prev => {
                const nextTime = prev.getTime() - (86400 * 1000);
                return new Date(nextTime);
            });
    }

    function nextDay(){
        if(nextDayEnabled)
            setDate(prev => {
                const nextTime = prev.getTime() + (86400 * 1000);
                return new Date(nextTime);
            });
    }

    function returnToOrg(){
        navigate(orgUrl);
    }

    useEffect(() => {
        function handleKeyDown(event){
            switch(event.code){
                case "ArrowDown":
                    nextPage(location.pathname);
                    break;
                case "ArrowUp":
                    prevPage(location.pathname);
                    break;
                case "ArrowLeft":
                    prevDay();
                    break;
                case "ArrowRight":
                    nextDay();
                    break;
                case "Escape":
                    returnToOrg();
                    break;

            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [nextDay, prevDay, location, routes]);


    if (wearable.json.firstSeen === null || wearable.json.lastSeen === null){
        return <Container><NonIdealState title={"No Data"} description={"This wearable has not collected any data yet."}/></Container>;
    }
    return <Container>
        <div className="wearable-view-top d-flex flex-column flex-xl-row">
            <Nav variant="pills" className="flex-grow-1" activeKey={location.pathname}>
                <Nav.Item key="back">
                    <LinkContainer to={orgUrl}>
                        <Nav.Link><ArrowLeft /></Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                {
                    routes.map((route, i) =>
                        <Nav.Item key={route.path}>
                            <LinkContainer to={route.path}>
                                <Nav.Link>{route.name}</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    )
                }
            </Nav>

            <div className="text-xl-end">
                <Button variant="secondary" onClick={() => prevDay()} disabled={!prevDayEnabled}><ArrowLeft /></Button>
                <Button variant="secondary" className="date-block" onClick={() => setDatePickerOpen(prev => !prev)}>
                    <Moment format="MM/DD/YYYY">{date}</Moment> <ChevronDown />
                </Button>
                <Button variant="secondary" onClick={() => nextDay()} disabled={!nextDayEnabled}><ArrowRight /></Button>
                <Collapse in={datePickerOpen}>
                    <DatePicker
                        value={date > wearable.firstSeen && date < wearable.lastSeen ? date : undefined}
                        onChange={date => {setDate(date); setDatePickerOpen(false)}}
                        minDate={wearable.firstSeen}
                        maxDate={wearable.lastSeen}
                        canClearSelection={false}
                    />
                </Collapse>
            </div>
        </div>
        <Outlet/>

    </Container>
}

export default WearableView;