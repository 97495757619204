import Plot from "../../node_modules/react-plotly.js/react-plotly";
import React from "react";
import {range} from "d3-array";

function RangePlot(props){
    const {
        timespan, wearableMetrics,
        minCol, maxCol,
        width, height,
        title, yAxisLabel
    } = props;

    const ts = range(timespan[0] / 1000, timespan[1] / 1000, 3600);
    const x = ts.map(row => new Date(row * 1000));

    function buildY(y) {
        return ts.map(row => {
            const rec = wearableMetrics.range.find(metric => metric.timestamp === row);
            if (rec)
                return rec[y];
            else
                return undefined;
        });
    }

    const minY = buildY(minCol);
    const maxY = buildY(maxCol);
    const trace = {
        x,
        close: maxY,
        decreasing: {line: {color: '#7F7F7F'}},
        high: maxY,
        increasing: {line: {color: '#17BECF'}},
        line: {color: 'rgba(31,119,180,1)'},
        low: minY,
        open: minY,
        type: 'candlestick',
        xaxis: 'x',
        yaxis: 'y',
        hoverinfo: "x"
    }
    return <div className="chart-container">
        <Plot
            data={[trace]}
            layout={{
                width,
                height,
                title,
                titlefont: {
                    family: 'Arial, sans-serif',
                    size: 18,
                    color: 'black'
                },
                barmode: 'stack',
                xaxis: {
                    range: timespan,
                    rangeslider: {visible: false},
                    tickfont: {
                        size: 14
                    }
                },
                yaxis: {
                    title: yAxisLabel,
                    titlefont: {
                        family: 'Arial, sans-serif',
                        size: 18,
                        color: 'black'
                    },
                    tickfont: {
                        size: 14
                    }
                }
            }}
            config={{displayModeBar: false}}
        />
    </div>;
}

export default RangePlot;