import React from 'react';
import {useBoxDimensions} from "../windowDimensions";
import FullscreenSpinner from "../FullscreenSpinner";
import SumPlot from "../plots/SumPlot";
import {Card, Col, Row} from "react-bootstrap";


function WearableCalorieView(props){
    const {organization, wearable, wearableMetrics, context} = props;

    const {width, height} = useBoxDimensions("chart-container");

    if (wearableMetrics === undefined){
        return <FullscreenSpinner />;
    }

    const timespan = [context.date.getTime(),  context.date.getTime() + 86400 * 1000];

    return <>
        <Row className="metric-bar">
            <Col md={3} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">Active Calories</p>
                        <p className="metric-value display-5">{ (wearableMetrics.sumMetric("delta_act_cals") / 1000).toFixed(1) } Cal</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={3} xs={6}>
                <Card className="metric-card">
                    <Card.Body>
                        <p className="metric-label">BMR Calories</p>
                        <p className="metric-value display-5">{ (wearableMetrics.sumMetric("delta_bmr_cals") / 1000).toFixed(1) } Cal</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <SumPlot
            timespan={timespan}
            wearableMetrics={wearableMetrics}
            width={width - 10}
            height={height - 10}
            title="Calories"
            yCols={[
                {y:"delta_act_cals", name: "Active", f:val => val / 1000},
                {y:"delta_bmr_cals", name: "BMR", f:val => val / 1000}
            ]}
            yAxisLabel="Kilocalories"
        />
        </>
}

export default WearableCalorieView;